.button,
button:not([data-filter]):not(.mfp-arrow):not(.carousel__button),
input[type="button"],
input[type="reset"],
input[type="submit"] {
  position: relative;
  color: $heading-color;
  background-color: $assistant-color;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 20px 41px;
  transition: background 0.3s, color 0.3s;
  overflow: hidden;

  &:before {
    content: "";
    background-color: $assistant-color;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0.2;
  }

  &:hover {
    &::before {
      animation: hover-btn 0.8s cubic-bezier(0.77, 0, 0.175, 1) both;
      width: 100%;
    }
  }
}

.form-button:disabled{
  background-color: #333b !important;
  cursor: not-allowed;

  .spin{
    margin-right: 10px;
    animation: spinner 1s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes hover-btn {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
